<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="companiesList"
        :search="search"
        :loading="loading"
        :options.sync="options"
        :server-items-length="getCompaniesTotalCount"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar color="indigo lighten-5" flat>
            <BackButton />
            <v-toolbar-title>Компанії</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              class="shrink mr-10"
              append-icon="mdi-magnify"
              label="Пошук"
              single-line
              hide-details
            ></v-text-field>
            <v-dialog v-model="dialog">
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="forceRerender" color="primary" small v-bind="attrs" v-on="on"> Додати компанію </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Назва"
                          @input="$v.editedItem.name.$touch()"
                          @blur="$v.editedItem.name.$touch()"
                          :error-messages="nameErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          v-model="editedItem.company_type"
                          :items="COMPANIES_TYPES"
                          item-text="title"
                          item-value="type"
                          label="Тип"
                          @input="$v.editedItem.company_type.$touch()"
                          @blur="$v.editedItem.company_type.$touch()"
                          :error-messages="typeErrors"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <AddressAutocomplete
                          v-if="isComponentRerendered"
                          ref="addressAutocomplete"
                          @updateItemsList="updateItemsList"
                          :entityId="clickedCompanyId"
                          :addressId="clickedAddressId"
                          :is-data-saved="isDataSaved"
                          type="company"
                          :key="addressAutocompleteKey"
                        ></AddressAutocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.site"
                          label="Сайт"
                          @input="$v.editedItem.site.$touch()"
                          @blur="$v.editedItem.site.$touch()"
                          :error-messages="siteErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.phone"
                          label="Телефон"
                          @input="$v.editedItem.phone.$touch()"
                          @blur="$v.editedItem.phone.$touch()"
                          :error-messages="phoneErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.liqpay_id"
                          label="Ідентифікатор в Liqpay"
                          @input="$v.editedItem.liqpay_id.$touch()"
                          @blur="$v.editedItem.liqpay_id.$touch()"
                          :error-messages="liqpayErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model.number="editedItem.payment_commission"
                          label="Комісія системи"
                          @input="$v.editedItem.payment_commission.$touch()"
                          @blur="$v.editedItem.payment_commission.$touch()"
                          :error-messages="paymentCommissionErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model.trim="editedItem.tin"
                          label="ЄДРПОУ (IПН)"
                          counter
                          @input="$v.editedItem.tin.$touch()"
                          @blur="$v.editedItem.tin.$touch()"
                          :error-messages="tinErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <PagingAutocomplete
                          :value="editedItem.owner"
                          :options="users"
                          :options-meta="usersMeta"
                          :options-params="usersOptions"
                          @change="(val) => (editedItem.owner = val)"
                          label="Власник компанії"
                          placeholder="Почніть вводити текст для пошуку"
                          no-filter
                          item-text="phone"
                          item-value="id"
                          clearable
                        >
                          <template v-slot:selection="{ item }">
                            {{ item.first_name }} {{ item.last_name }} ({{ item.phone }})
                          </template>
                          <template v-slot:item="{ item }">
                            {{ item.first_name }} {{ item.last_name }} ({{ item.phone }})
                          </template>
                        </PagingAutocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="8">
                        <v-row>
                          <v-textarea
                            v-model="editedItem.description"
                            label="Опис*"
                            counter
                            outlined
                            @input="$v.editedItem.description.$touch()"
                            @blur="$v.editedItem.description.$touch()"
                            :error-messages="descriptionErrors"
                          ></v-textarea>
                          <v-btn v-if="editedIndex !== -1" class="ml-4" @click="showLockCompanyModal = true">{{
                            companyLockText
                          }}</v-btn>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="save">Так</v-btn>
                  <v-btn text @click="close">Нi</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <ConfirmActionModal @closeModal="showLockCompanyModal = false" :showModal="showLockCompanyModal">
              <template v-slot:title>Підтвердження</template>
              <template v-slot:text>{{ companyLockText }}?</template>
              <template v-slot:buttons>
                <v-btn @click="doLockAction" color="primary" text>Так</v-btn>
                <v-btn @click="showLockCompanyModal = false" text>Нi</v-btn>
              </template>
            </ConfirmActionModal>

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">Компанію буде видалено</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="deleteItemConfirm">Так</v-btn>
                  <v-btn text @click="closeDelete">Нi</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.name="{ item }">
          <router-link :to="{ name: 'CompanyCard', params: { id: item.id } }" class="text-decoration-none">
            <v-tooltip bottom :open-on-focus="false">
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-if="item.status !== null && item.status !== 1"
                  :style="{ color: getListStatusColor(item.status) }"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.name }}
                </span>
                <span v-else v-bind="attrs" v-on="on">{{ item.name }}</span>
              </template>
              <span>{{ getListStatusTitle(item.status) }}</span>
            </v-tooltip>
          </router-link>
        </template>
        <template v-slot:item.address="{ item }">
          <template>
            <router-link v-if="item.address" :to="{ name: 'CompanyCard', params: { id: item.id } }" class="text-decoration-none">
              {{ item.address.country }}, {{ item.address.city }}, {{ item.address.street }},
              {{ item.address.building_number }}
            </router-link>
          </template>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil-outline </v-icon>
          <v-icon small class="mr-2" @click="deleteItem(item)"> mdi-delete-outline </v-icon>
          <v-icon small @click="navigateToCompanyCard(item)"> mdi-eye-outline </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="resetData">Оновити</v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { COMPANY_SCHEMA } from '@/const/apiSchemas'
import { COMPANIES_TYPES } from '@/const/companies-types.enum'
import { mergeObjectsDeep } from '@/helpers/mergeObjects'
import cloneDeep from '@/mixins/cloneDeep'
import { validationMixin } from 'vuelidate'
import { minLength, required, url } from 'vuelidate/lib/validators'
import resetTableData from '@/mixins/resetTableData'
import ConvertStatusesTypes from '@/mixins/convertStatusesTypes'
import ForceRerender from '@/mixins/forceRerender'
import UpdateItemsList from '@/mixins/updateItemsList'
import BackButton from '@/components/common/BackButton'
import sortUtils from '@/mixins/sortUtils'
import awaitingSearch from '@/mixins/constructor/awaitingSearch'

export default {
  name: 'CompaniesList',

  components: {
    BackButton,
    PagingAutocomplete: () => import('@/components/common/PagingAutocomplete'),
    ConfirmActionModal: () => import('@/components/dialogs/ConfirmActionModal'),
    AddressAutocomplete: () => import('@/components/partials/AddressAutocomplete'),
  },

  mixins: [
    validationMixin,
    resetTableData,
    cloneDeep,
    ConvertStatusesTypes,
    ForceRerender,
    UpdateItemsList,
    sortUtils,
    awaitingSearch,
  ],

  validations: {
    editedItem: {
      name: { required },
      company_type: { required },
      site: { url },
      phone: { required },
      liqpay_id: { required },
      payment_commission: { required },
      tin: { required, minLength: minLength(8) },
      description: { required, minLength: minLength(10) },
    },
  },

  data: () => ({
    COMPANIES_TYPES: COMPANIES_TYPES,
    dialog: false,
    dialogDelete: false,
    showLockCompanyModal: false,
    search: '',
    loading: false,
    isUserLoading: false,
    clickedCompanyId: null,
    clickedAddressId: null,
    company: {},
    headers: [
      { text: 'Назва', align: 'start', sortable: true, value: 'name' },
      { text: 'Адреса', value: 'address', sortable: false },
      { text: 'Опис', value: 'description', sortable: true },
      { text: 'Сайт', value: 'site', sortable: true },
      { text: 'Телефон', value: 'phone', sortable: true },
      { text: '', value: 'actions', sortable: false, width: '100px', align: 'center' },
    ],
    companiesList: [],
    editedIndex: -1,
    isDataSaved: false,
    sortBy: '',
    sortDesc: false,
    options: {},
    editedItem: {},
    defaultItem: {},
    addressAutocompleteKey: 0,
    usersOptions: null,
  }),

  computed: {
    ...mapGetters('companies', ['getCompaniesTotalCount']),
    ...mapState('companies', ['companies']),
    ...mapState('profile', ['user']),
    ...mapState('users', ['users', 'usersMeta']),
    ...mapState('admin', { owner: (state) => state.user }),

    currentCompanyId() {
      return this.$route.params.id
    },
    formTitle() {
      return this.editedIndex === -1 ? 'Створити компанію' : 'Редагувати компанію'
    },
    nameErrors() {
      const errors = []
      if (!this.$v.editedItem.name.$dirty) return errors
      !this.$v.editedItem.name.required && errors.push('Це поле обов"язкове')
      return errors
    },
    typeErrors() {
      const errors = []
      if (!this.$v.editedItem.company_type.$dirty) return errors
      !this.$v.editedItem.company_type.required && errors.push('Це поле обов"язкове')
      return errors
    },
    siteErrors() {
      const errors = []
      if (!this.$v.editedItem.site.$dirty) return errors
      !this.$v.editedItem.site.url && errors.push('https://test.com або http://test.com')
      return errors
    },
    phoneErrors() {
      const errors = []
      if (!this.$v.editedItem.phone.$dirty) return errors
      !this.$v.editedItem.phone.required && errors.push('Це поле обов"язкове')
      return errors
    },
    liqpayErrors() {
      const errors = []
      if (!this.$v.editedItem.liqpay_id.$dirty) return errors
      !this.$v.editedItem.liqpay_id.required && errors.push('Це поле обов"язкове')
      return errors
    },
    paymentCommissionErrors() {
      const errors = []
      if (!this.$v.editedItem.payment_commission.$dirty) return errors
      !this.$v.editedItem.payment_commission.required && errors.push('Це поле обов"язкове')
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.$v.editedItem.description.$dirty) return errors
      !this.$v.editedItem.description.minLength && errors.push('Мінімум 10 символів')
      !this.$v.editedItem.description.required && errors.push('Це поле обов"язкове')
      return errors
    },
    tinErrors() {
      const errors = []
      if (!this.$v.editedItem.tin.$dirty) return errors
      !this.$v.editedItem.tin.minLength && errors.push('Мінімум 8 символів')
      !this.$v.editedItem.tin.required && errors.push('Це поле обов"язкове')
      return errors
    },
    companyLockText() {
      return this.editedItem.status - 1 ? 'Розблокувати компанію' : 'Заблокувати компанію'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    options: {
      handler() {
        this.paginateTo()
      },
      deep: true,
    },
  },

  mounted() {
    this.clone()
  },

  methods: {
    ...mapActions('companies', [
      'getSelectedCompany',
      'getCompanies',
      'createNewCompany',
      'updateSelectedCompany',
      'updateCompanyStatus',
      'deleteSelectedCompany',
      'getAllCompaniesNoFilter',
    ]),
    ...mapActions('addresses', ['deleteAddress']),
    ...mapActions('users', ['loadUsers']),
    ...mapActions('admin', ['loadSelectedUser']),

    clone() {
      this.editedItem = { ...this.cloneObjectDeep(COMPANY_SCHEMA) }
      this.defaultItem = { ...this.cloneObjectDeep(COMPANY_SCHEMA) }
    },

    initialize() {
      this.companiesList = [...this.companies]
      this.usersOptions = {
        loadingFunction: this.loadUsers,
        payload: {
          companyId: this.currentCompanyId,
          forSearch: 1,
        },
      }
    },

    async paginateTo() {
      this.loading = true
      const payload = {
        page: this.options.page,
        limit: this.options.itemsPerPage,
        sort: this.sortObject,
        search: this.search,
      }
      await this.getCompanies(payload)
      if (this.isDataSaved) await this.getAllCompaniesNoFilter()
      this.initialize()
      this.loading = false
    },

    editItem(item) {
      this.company = { ...item }
      this.editedIndex = this.companiesList.indexOf(item)
      this.editedItem = mergeObjectsDeep(this.editedItem, item)
      this.clickedCompanyId = item.id
      this.clickedAddressId = item.address?.id
      this.forceRerender()
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.companiesList.indexOf(item)
      this.clickedCompanyId = item.id
      this.clickedAddressId = item.address?.id
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.loading = true
      const deleteAddressPayload = {
        type: 'company',
        companyId: this.clickedCompanyId,
        addressId: this.clickedAddressId,
      }
      await Promise.all([this.deleteSelectedCompany(this.clickedCompanyId), this.deleteAddress(deleteAddressPayload)])
      this.closeDelete()
      await this.paginateTo()
      this.loading = false
    },

    close() {
      this.dialog = false
      this.company = {}
      this.$v.editedItem.$reset()
      this.addressAutocompleteKey++
      this.$nextTick(() => {
        this.clone()
        this.editedIndex = -1
        this.clickedCompanyId = null
        this.clickedAddressId = null
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.clickedCompanyId = null
        this.clickedAddressId = null
      })
    },

    async save() {
      this.$refs.addressAutocomplete.handleButtonClicked()
      this.$v.editedItem.$touch()
      if (
        this.$v.$anyError ||
        this.$refs.addressAutocomplete.isAddressEmpty ||
        !this.$refs.addressAutocomplete.editedItem.building_number
      )
        return
      this.loading = true
      if (this.editedIndex > -1) {
        const payload = {
          updatedCompany: this.editedItem,
          id: this.clickedCompanyId,
        }
        delete payload.updatedCompany.id
        delete payload.updatedCompany.status
        await this.updateSelectedCompany(payload)
      } else {
        this.editedItem.contacts = {
          technical: null,
          personal: null,
        }
        delete this.editedItem.id
        await this.createNewCompany(this.editedItem)
      }
      this.clone()
      this.isDataSaved = true
    },

    navigateToCompanyCard(item) {
      const clickedCompanyId = item.id
      this.$router.push({
        name: 'CompanyCard',
        params: { id: clickedCompanyId },
      })
    },

    async doLockAction() {
      this.showLockCompanyModal = false
      const payload = {
        id: this.editedItem.id,
        status: this.editedItem.status === 1 ? 2 : 1,
      }
      await this.updateCompanyStatus(payload)
      this.dialog = false
      await this.paginateTo()
    },
  },
}
</script>

<style scoped></style>
