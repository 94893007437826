const OWNER = 'Власник'
const SELLER = 'Продавець'
const OWNER_SELLER = 'Власник/Продавець'

export const COMPANIES_TYPES = Object.freeze([
  {
    title: OWNER,
    type: 1,
  },
  {
    title: SELLER,
    type: 2,
  },
  {
    title: OWNER_SELLER,
    type: 3,
  },
])
